/********LIBRERIA********* */
import Vue from 'vue';
import App from './App.vue';
import vuetify from './plugins/vuetify';
import router from './router';
import Vuesax from 'vuesax';
import axios from 'axios';
import VueAxios from 'vue-axios';
import moment from 'moment';
/********LIBRERIA********* */

/********CSSS********* */
import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
import 'boxicons/css/boxicons.css'
/********CSSS********* */


/********FUNSION********* */
moment.locale('es');
/********FUNSION********* */


Vue.config.productionTip = false;
Vue.prototype.moment = moment;


Vue.use(VueAxios, axios);
Vue.use(Vuesax);
new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
