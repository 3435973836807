import Cookies from "js-cookie";
export default {

    async guardar_usuario(token) {
        await Cookies.set("auth-token-jg", token);
    },
    async borrar_usuario() {
        await Cookies.remove("auth-token-jg");
    },
    async validar_sesion() {
        if (Cookies.get("auth-token-jg")) {
            return true
        } else {
            return false
        }

    },
};