import Vue from 'vue'
import VueRouter from 'vue-router'
import cookie from '@/global/cookie'
Vue.use(VueRouter)

const routes = [
  {
    path: "/",
    name: "landing",
    component: () => import("../views/landing/app.vue"),
  },
  {
    path: "/auth",
    name: "auth",
    component: () => import("../views/auth/app.vue"),
  },
  {
    path: "/admin",
    name: "admin",
    meta: {
      requiresAuth: true
    },

    component: () => import("../views/admin/app.vue"),
    children: [
      {
        path: "/admin/init",
        name: "Init",
        meta: {
          requiresAuth: true
        },

        component: () => import("../views/admin/fragment/init.vue"),
      },
      {
        path: "/admin/global",
        name: "Global",
        meta: {
          requiresAuth: true
        },

        component: () => import("../views/admin/fragment/global.vue"),
      },
      {
        path: "/admin/news",
        name: "News",
        meta: {
          requiresAuth: true
        },

        component: () => import("../views/admin/fragment/news.vue"),
      },
      {
        path: "/admin/store",
        name: "Store",
        meta: {
          requiresAuth: true
        },

        component: () => import("../views/admin/fragment/store.vue"),
      },
      {
        path: "/admin/player",
        name: "Player",
        meta: {
          requiresAuth: true
        },

        component: () => import("../views/admin/fragment/player.vue"),
      },
      {
        path: "/admin/opposing/team",
        name: "Opposing Team",
        component: () => import("../views/admin/fragment/opposing_team.vue"),
      },
      {
        path: "/admin/ticket",
        name: "Ticket",
        meta: {
          requiresAuth: true
        },

        component: () => import("../views/admin/fragment/ticket.vue"),
      },
      {
        path: "/admin/game",
        name: "Game",
        meta: {
          requiresAuth: true
        },

        component: () => import("../views/admin/fragment/game.vue"),
      },
      {
        path: "/admin/team",
        name: "Team",
        meta: {
          requiresAuth: true
        },

        component: () => import("../views/admin/fragment/team.vue"),
      },
    ],
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach(async (to, from, next) => {
  var cokie_user = await cookie.validar_sesion();
  //console.log(cookie.usuario.iniciado);
  if (to.name != 'landing' && to.name != 'auth' && to.meta.requiresAuth) {
    //console.log("!= Login")
    if (cokie_user == false) {
      next('/');
    } else {
      next();
    }
  } else {
    next();
  }


})


export default router;
